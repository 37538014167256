<template>
    <div class="chart-wrapper">
      <canvas id="totalAdvertsPublishedGraph" class="chart chart-bar"></canvas>
    </div>
</template>

<script>
    import moment from "moment";
    import Chart from "chart.js";

    import {log} from "@/shared/utils";

    export default {
        name: "ChartVerticalBarReportTotalAdvertsPublished",
        props: {
            chartData: {
                type: Array
            },
        },
        data() {
            return {
                totalAdvertsPublishedGraph : null,
                ctx: null,
            }
        },
        computed: {
            chartBarColour(){
                const barColour = this.ctx.createLinearGradient(0, 0, 0, 200);
                barColour.addColorStop(0, '#677CE7');
                barColour.addColorStop(1, '#7152AB');
                return barColour;
            },
            chartAxisTextColour(){
                return "#838383";
            },
            chartAxisFontFamily(){
                return 'dialog_light, "Open Sans", sans-serif';
            },
            chartAxisLineColour(){
                return "#ec6468";
            }
        },
        methods: {
            initChart() {

                if(this.totalAdvertsPublishedGraph){
                    log(["Chart exists - destroy it before we build another", this.totalAdvertsPublishedGraph]);
                    this.totalAdvertsPublishedGraph.destroy();
                }

                log(["Build chart", this.chartData, document.getElementById('totalAdvertsPublishedGraph')]);

                let labels = this.chartData.map(row => {
                    return moment(`${row.year}-${row.month}-01`, "YYYY-M-DD").format('MMM');
                });
                let data = this.chartData.map(row => {
                    return row.count;
                });

                this.ctx = document.getElementById('totalAdvertsPublishedGraph').getContext('2d');
                this.totalAdvertsPublishedGraph = new Chart(this.ctx, {
                    // The type of chart we want to create
                    type: 'bar',

                    // The data for our dataset
                    data: {
                        labels: labels,
                        datasets: [{
                            label: 'Number of adverts published',
                            backgroundColor: this.chartBarColour,
                            data: data
                        }]
                    },

                    // Configuration options go here
                    options: {
                        responsive: true,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display:false
                                },
                                ticks: {
                                    fontColor: this.chartAxisTextColour,
                                    fontFamily: this.chartAxisFontFamily
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                    zeroLineColor: this.chartAxisLineColour,
                                },
                                ticks: {
                                    display: false
                                }
                            }]
                        },
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                    }
                });
            }
        },
        mounted() {
            this.initChart();
        },
        watch:{
            chartData (){
                log(["CHART DATA CHANGE"]);
                this.initChart();
            }
        }
    }
</script>